import { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Skeleton,
  Tabs,
} from 'antd';
import { ChromePicker } from 'react-color';
import { useTranslation } from 'react-i18next';

import APIService from '@/services/API';
import { ConfElement, RawConfiguration } from '@/interfaces/configuration.interface';

const configFields = {
  custom: [
    'contact-emails',
    'email-method',
    'email-endpoint-api',
    'email-auth-token',
    'email-from',
  ],
  sendiblue: [
    'contact-emails',
    'email-method',
    'email-api-url',
    'email-api-key',
    'email-send-name',
    'email-send-email',
  ],
  manual: [
    'contact-emails',
    'email-method',
    'email-host',
    'email-port',
    'email-secure',
    'email-send-email',
    'email-send-password',
    'email-send-name',
  ],
};

type ConfigFieldKey = keyof typeof configFields; // 'custom' | 'sendiblue' | 'manual'

function parseData(data: RawConfiguration[]): RawConfiguration[] {
  for (const index in data) {
    for (const key in data[index].elements) {
      if (data[index].elements[key].type === 'json') {
        data[index].elements[key].value = JSON.stringify(data[index].elements[key].value);
      }
    }
  }

  return data;
}

function restoreData(elements: ConfElement[]): ConfElement[] {
  const data: ConfElement[] = [];
  for (const element of elements) {
    if (element.type === 'json') {
      element.value = JSON.parse(element.value);
      data.push(element);
    } else {
      data.push(element);
    }
  }

  return data;
}

const ConfigTabView = () => {
  const [data, setData] = useState<RawConfiguration[]>([]);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [selectedSlug, setSelectedSlug] = useState<string[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    async function get() {
      setLoading(true);
      const response = await APIService.getConfigs();
      if (response?.data?.length) {
        setData(parseData(response.data));

        // Check selected email method
        const emailConfig = response.data.find((item) => item.slug === 'email-config');
        if (emailConfig) {
          const emailMethod = emailConfig.elements.find(
            (item) => item.slug === 'email-method',
          );
          if (emailMethod) {
            const configKey = emailMethod.value as ConfigFieldKey;
            setSelectedSlug(configFields[configKey]);
          }
        }
      }
      setLoading(false);
    }
    get();
  }, []);

  const handleSave = async () => {
    let blnError = false;
    setBtnLoading(true);
    for (const { _id, elements } of data) {
      const copyElements = elements.map((item) => ({ ...item }));

      const response = await APIService.updateConfig(_id, {
        elements: restoreData(copyElements),
      });

      if (!response.ok) {
        message.success(t('configurations.errorUpdate'));
        blnError = true;
        break;
      }
    }
    setBtnLoading(false);
    if (!blnError) {
      message.success(t('configurations.updateSuccess'));
    }
  };

  const handleChangeValue = (value: any, index: number, key: string, slug: string) => {
    if (key === 'email-config' && slug === 'email-method') {
      const configKey = value as ConfigFieldKey;
      setSelectedSlug(configFields[configKey]);
    }

    setData((prevItems) =>
      prevItems.map((item) => {
        if (item.slug === key) {
          return {
            ...item,
            elements: item.elements.map((el, i) => {
              if (i === index) {
                return { ...el, value };
              }

              return el;
            }),
          };
        }

        return item;
      }),
    );
  };

  const checkDisplayed = (key: string, slug?: string): string => {
    if (key === 'email-config' && slug !== undefined && selectedSlug?.length) {
      if (selectedSlug.indexOf(slug) > -1) {
        return 'block';
      } else {
        return 'none';
      }
    }

    return 'block';
  };

  const renderInput = (node: ConfElement, index: number, key: string) => {
    switch (node.type) {
      case 'select':
        return (
          <Select
            value={node.value}
            style={{ width: '100%' }}
            onChange={(e) => handleChangeValue(e, index, key, node?.slug || '')}
          >
            {node?.options?.map((item, index) => (
              <Select.Option key={index} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        );
      case 'number':
        return (
          <InputNumber
            min={0}
            style={{ width: '100%' }}
            value={node.value}
            onChange={(e) => handleChangeValue(e, index, key, node?.slug || '')}
          />
        );
      case 'json':
        return (
          <Input.TextArea
            value={node.value}
            onChange={(e) =>
              handleChangeValue(e.target.value, index, key, node?.slug || '')
            }
          />
        );
      case 'color':
        return (
          <ChromePicker
            color={node.value}
            onChange={(e) => handleChangeValue(e.hex, index, key, node?.slug || '')}
          />
        );
      case 'password':
        return (
          <Input.Password
            style={{ width: '100%' }}
            value={node.value}
            onChange={(e) =>
              handleChangeValue(e.target.value, index, key, node?.slug || '')
            }
          />
        );
      default:
        return (
          <Input
            style={{ width: '100%' }}
            value={node.value}
            onChange={(e) =>
              handleChangeValue(e.target.value, index, key, node?.slug || '')
            }
          />
        );
    }
  };

  const renderElements = (elements: ConfElement[], key: string, index: number) => {
    return (
      <Row key={`row-${key}-${index}`} gutter={[0, 15]} justify="center">
        {elements.map((element, i) => (
          <Col
            xs={24}
            md={18}
            key={element.slug}
            style={{ display: checkDisplayed(key, element.slug) }}
          >
            <Row gutter={[10, 0]} align="middle">
              <Col md={6} className="text-right">
                {element.name}
              </Col>
              <Col flex="1">{renderInput(element, i, key)}</Col>
            </Row>
          </Col>
        ))}
      </Row>
    );
  };

  const renderContent = () => {
    if (loading) {
      return <Skeleton active />;
    }

    return (
      <Tabs
        items={data.map((row, i) => {
          return {
            key: row.slug,
            label: row.name,
            children: (() => <>{renderElements(row.elements, row.slug, i)}</>)(),
          };
        })}
      />
    );
  };

  return (
    <>
      <Row justify="end">
        <Col>
          <Button type="primary" onClick={handleSave} loading={btnLoading}>
            {t('general.save')}
          </Button>
        </Col>
      </Row>
      {renderContent()}
    </>
  );
};

export default ConfigTabView;
